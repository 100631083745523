import React from "react";
import PropTypes from "prop-types";

import { Facebook } from "@mui/icons-material";

import "./product_card.css"
import { FacebookIcon, FacebookShareButton } from "react-share";

const ProductCard = (props) => {
	const cardTitle = props.bookTitle + " adatlapja"

	const googleButtonImageURL = "https://play.google.com/intl/en_us/badges/static/images/badges/hu_badge_web_generic.png"
	const googleButtonAlt = "Szerezd be: Google Play-en"
	const googleButtonTitle = props.bookTitle + " a Google Play-ről"

	return (
		<a href={props.productURL} title={cardTitle} className="item" target="_self">
			<img src={props.bookCover} />
			<div className="content">
				<div className="book-details">
					<span className="book-title">{props.bookTitle}</span>
					<span className="book-author">{props.bookAuthor}</span>
				</div>
				<div className="buttons">
					<a className="google-button" href={props.googleProductURL} target="_blank">
						<img title={googleButtonTitle} alt={googleButtonAlt} src={googleButtonImageURL} />
					</a>
					<a className="facebook-button" title="Megosztás Facebook-on" >
						<FacebookShareButton 
							url={props.googleProductURL}
							quote="Megosztás a Facebookon">
								<div className="social-button facebook">
									<Facebook />
									<span>
										Megosztás
									</span>
								</div>
						</FacebookShareButton>
					</a>
				</div>
			</div>
		</a>
	)
}

ProductCard.propTypes = {
	bookCover: PropTypes.string,
	bookTitle: PropTypes.string,
	bookAuthor: PropTypes.string,
	productURL: PropTypes.string,
	googleProductURL: PropTypes.string,
}

export default ProductCard

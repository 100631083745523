import React, { useState } from "react";
import PropTypes from "prop-types";

import { AutoStories, Lock, TrendingUp } from '@mui/icons-material';
import Mark from "../components/mark";

import './activities.css';
import Products from "./products";

export const ActivitiesType = {
	BOOK_PUBLISHING: 0,
	QUALITY_MANAGEMENT_SYSTEM: 1,
	PERSONAL_DATA_PROTECTION: 2,
}

const Activities = (props) => {
	const [selectedFeature, setSelectedFeature] = useState(props.selectedFeature)

	return (
		<section className="home-section01" id={props.id}>
			<h2 className="home-text02">Tevékenységeink</h2>
			<div className="home-features">
				<header className={`feature ${selectedFeature == 0 ? "feature-active" : ""}`} onClick={() => {setSelectedFeature(0)}}>
					<div className="feature-lead-icon">
						<AutoStories />
					</div>
					<div className="feature-body">
						<h3 className="home-text03">Könyvkiadás</h3>
						<p className="home-text04">Szerkesztés, Kiadás, Terjesztés, Marketing</p>
					</div>
				</header>
				<header className={`feature ${selectedFeature == 1 ? "feature-active" : ""}`} onClick={() => {setSelectedFeature(1)}}>
					<div className="feature-lead-icon">
						<TrendingUp />
					</div>
					<div className="feature-body">
						<h3 className="home-text05">Minőségirányítás (MIR)</h3>
						<p className="home-text06">ISO 9001 szabvány alapján tanácsadás</p>
					</div>
				</header>
				<header className={`feature ${selectedFeature == 2 ? "feature-active" : ""}`}  onClick={() => {setSelectedFeature(2)}}>
					<div className="feature-lead-icon">
						<Lock />
					</div>
					<div className="feature-body">
						<h3 className="home-text07">Adatvédelem (GDPR)</h3>
						<p className="home-text08">Személyes adatvédelem tanácsadás, DPO</p>
					</div>
				</header>
			</div>


			{/* Book Publish */}
			<div className={`home-note ${selectedFeature == 0 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Könyvkiadás
						</h2>
						<p className="home-paragraph">
							<span>
								Könyveinket elsősorban elektronikus úton terjesztjük, így kedvenc olvasmányait bárhová magával viheti, miközben óvja a természetet. <br />
								<br />
								Könyveink elérhetőek a Google kínálatában. 
							</span>
							<br></br>
							<br></br>
							<span>
								Tekintse meg újonnan megjelent kiadásainkat, vagy a teljes könyvkatalógusunkat!
							</span>
							<br></br>
						</p>
					</main>
					<a href="https://play.google.com/store/search?q=Kathy%20Schranko&c=books&hl=hu" className="home-get-started button">
						<span className="home-text">Könyvkatalógus</span>
					</a>
				</div>
				<div className="home-image07 modification">
					<h5 className="home-heading01 modification">
						Újonnan megjelentek:
					</h5>
					<Products />
				</div>
			</div>


			{/* Quality Control */}
			<div className={`home-note ${selectedFeature == 1 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Minőségirányítás
						</h2>
						<p className="home-paragraph">
							<span>
							Vállaljuk Minőségirányítási rendszer (MIR) kiépítését, a folyamatok dokumentálását, tájékoztatást a dolgozók számára, tudásuk visszamérését, szabályzatok aktualizálását, helyszíni auditok elvégzését, minőségügyi vezetői tevékenységet.<br/>
							Belső kontroll környezet, ellenőrzési nyomvonalak kialakítása, felelős felkészítése.  Személyes találkozásokkor tanácsadást végzünk, amellyel segítjük az Intézmény/Társaság felsővezetésének objektív döntéshozatalát.
							</span>
							<br/>
							<br/>
							<div className="home-checkmarks">
								<Mark Label="Minőségirányítási (ISO 9001) rendszer építése, működtetése, fejlesztése"/>
								<Mark Label="Belső kontroll környezet kialakítása" />
							</div>
							<br></br>
						</p>
					</main>
					<a target="_blank" href="/documents/MINO%CC%8BSE%CC%81GIRA%CC%81NYI%CC%81TA%CC%81SI%20RENDSZER%20o%CC%88sszefoglalo%CC%81_2025.pdf"  className="home-get-started button">
						<span className="home-text">Bővebben</span>
					</a>
				</div>
				<div className="home-image07 modification">
					<img
						alt="minoseg_iso9001.png"
						src="/assets/minoseg_iso9001.png"
						className="home-image08b"
					/>

				</div>
			</div>


			{/* Data Protection */}
			<div className={`home-note ${selectedFeature == 2 ? "home-note-active" : "home-note-disable"}`}>
				<div className="home-image07">
					<img
						alt="image"
						src="/assets/gdpr.png"
						className="home-image08"
					/>
				</div>
				<div className="home-content1">
					<main className="home-main1">
						<h2 className="home-heading01">
							Adatvédelem
						</h2>
						<p className="home-paragraph">
							<span>
							Vállaljuk személyes adatok védelmi rendszerének kiépítését, a folyamatok dokumentálását, tájékoztatást a dolgozók számára, tudásuk visszamérését, szabályzatok aktualizálását, helyszíni auditok elvégzését,  DPO képviseletet.<br/>
							Személyes találkozásokkor tanácsadást végzünk, amellyel segítjük az Intézmény/Társaság felsővezetésének objektív döntéshozatalát.
							</span>
							<br/>
							<br/>
							<div className="home-checkmarks">
								<Mark Label="Személyes adatok védelme (GDPR) rendszer építése, működtetése, fejlesztése."/>
								<Mark Label="Adatvédelmi tisztviselő (DPO) képviselet biztosítása" />
							</div>
							<br></br>
						</p>
					</main>
					<a target="_blank" href="/documents/GDPR%20RENDSZER%20o%CC%88sszefoglalo%CC%81_2025.pdf" className="home-get-started button">
						<span className="home-text">Bővebben</span>
					</a>
				</div>
			</div>
		</section>
	);
};

Activities.propTypes = {
	selectedFeature: PropTypes.number,
	id: PropTypes.string,
}

export default Activities
